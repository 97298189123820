import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {NotificationService} from '../services/notification.service';
import {AuthenticationService} from '../services/authentication.service';

@Injectable()
export class HandlerErrorInterceptor implements HttpInterceptor {

  constructor(
    private notificationService: NotificationService,
    private authService: AuthenticationService
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let hideError = false;
    if (req.headers.get('HideError')) {
      req.headers.delete('HideError');
      hideError = true;
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.authService.logout();
          this.notificationService.error('У вас недостаточно прав для получения данных');
        } else if (!hideError) {
          this.notificationService.error('errorMessages.requestError');
        }
        return throwError(error);
      })
    );

  }

}
