import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppComponent } from './app.component'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { CommonModule, registerLocaleData } from '@angular/common'
import { InterceptorProviders } from './interceptor'
import { LoadingModule } from './widget-modules/loading/loading.module'
import { OverlayModule } from '@angular/cdk/overlay'
import { NZ_I18N, ru_RU } from 'ng-zorro-antd'
import { NzNotificationModule } from 'ng-zorro-antd/notification'
import ru from '@angular/common/locales/ru'
import { LayoutModule } from './widget-modules/layout/layout.module'
import { ApiService } from './services/api.service'

export let CustomTranslateService: TranslateService

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

registerLocaleData(ru)

@NgModule({
  declarations: [
    AppComponent,
  ],

  imports: [
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    LoadingModule,
    OverlayModule,
    NzNotificationModule,
    LayoutModule
  ],
  providers: [
    ApiService,
    InterceptorProviders,
    {provide: NZ_I18N, useValue: ru_RU}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: TranslateService) {
    CustomTranslateService = this.injector
  }
}


