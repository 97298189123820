import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LoadingService} from './widget-modules/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService
  ) {
    this.router.events.subscribe(evt => {
      if (evt instanceof NavigationStart) {
        this.loadingService.show();
      } else if (evt instanceof NavigationEnd || evt instanceof NavigationCancel || evt instanceof NavigationError) {
        this.loadingService.hide();
      }
    });
  }

  ngOnInit() {
    this.setDefaultLang();
  }

  setDefaultLang() {
    const tmpKey = localStorage.getItem('lang');
    if (tmpKey === 'ru' || tmpKey === 'kg') {
      this.translate.setDefaultLang(tmpKey);
    } else {
      this.translate.setDefaultLang('ru');
      localStorage.setItem('lang', tmpKey);
    }
  }

}
