import {Injectable} from '@angular/core';
import {NzConfigService, NzNotificationService} from 'ng-zorro-antd';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private nzNotificationService: NzNotificationService,
    private translateService: TranslateService,
    private nzConfigService: NzConfigService
  ) {
    this.nzConfigService.set('notification', {nzTop: '53px', nzMaxStack: 3});
  }

  error(titleKey: string, textKey = '') {
    this.translateService.get([titleKey, textKey]).subscribe(
      res => {
        this.nzNotificationService.error(res[titleKey], res[textKey]);
      }
    );
  }

  success(titleKey: string, textKey = '') {
    this.translateService.get([titleKey, textKey]).subscribe(
      res => {
        this.nzNotificationService.success(res[titleKey], res[textKey]);
      }
    );
  }

  warning(titleKey: string, textKey = '') {
    this.translateService.get([titleKey, textKey]).subscribe(
      res => {
        this.nzNotificationService.warning(res[titleKey], res[textKey]);
      }
    );
  }
}
