import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import { LayoutComponent } from './layout.component';
import {NzDropDownModule, NzIconModule, NzLayoutModule} from 'ng-zorro-antd';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    FooterComponent,
  ],
  exports: [
    LayoutComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NzLayoutModule,
    NzDropDownModule,
    NzIconModule,
    NzMenuModule
  ]
})
export class LayoutModule {
}
