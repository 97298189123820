import {Injectable} from '@angular/core';
import {UserDetail} from '../models/user';
import {map} from 'rxjs/operators';
import {HttpClient, HttpParams} from '@angular/common/http';
import {RequestCacheService} from './request-cache.service';
import {BehaviorSubject} from 'rxjs';
import {UserRoleCodes} from '../constants/user-role-codes';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private url = 'login';
  // private url = 'http://172.16.11.14/login'; // http://172.16.11.14

  authorized = new BehaviorSubject(false); // авторизованли пользователь

  currentUser: UserDetail;

  constructor(
    private httpClient: HttpClient,
    private requestCacheService: RequestCacheService,
    private router: Router
  ) {
    const tmpUser = localStorage.getItem('currentUser');
    if (tmpUser != null) {
      this.authorized.next(true);
      this.currentUser = JSON.parse(tmpUser);
    }
  }

  getRole() {
    return this.currentUser.role;
  }

  /**
   * @param array роли на который нужно проверит авторизованного пользователя
   */
  checkRoles(array: UserRoleCodes[]): boolean {
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (this.checkRole(element)) {
        return true;
      }
    }
    return false;
  }

  checkRole(roleCode: UserRoleCodes) {
    return roleCode === this.getCodeRoleCurrentUser();
  }

  getCurrentUserId() {
    try {
      return this.currentUser.id;
    } catch (error) {
      return null;
    }
  }

  getCodeRoleCurrentUser(): UserRoleCodes {
    try {
      return this.currentUser.role.code;
    } catch (error) {
      return null;
    }
  }

  getCompanyIdCurrentUser(): number {
    try {
      return this.currentUser.company.id;
    } catch (error) {
      return null;
    }
  }

  login(email: string, password: string) {
    const currentLang = localStorage.getItem('lang');
    localStorage.clear();
    localStorage.setItem('lang', currentLang);

    const formData = new FormData();
    let params = new HttpParams();
    formData.append('username', email);
    formData.append('password', password);
    // HttpParams and HttpHeaders are immutable classes so after each call of
    // set or append methods they return a new instance that's why you should do
    // this : params = params.append(...)
    params = params.append('lang', currentLang === 'ru' ? '0' : '1');

    return this.httpClient.post<UserDetail>(this.url, formData,
      {headers: {HideError: 'true'}, params}).pipe(
      map((res: any) => {
        this.currentUser = res;
        localStorage.setItem(
          'currentUser',
          JSON.stringify(this.currentUser)
        );
        console.log(this.currentUser)
        this.authorized.next(true);
        return res;
      })
    );
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.authorized.next(false);
    this.router.navigate(['/']);
  }

  /**
   * только для сотрудников фуги
   * получение список компании которые они обслуживает
   */
  getServicedCompany() {
    return this.httpClient.get<any>('api/registry/filter', {headers: {Caching: 'ServiceCompany'}}).pipe(
      map(res => {
        return res.companyIds ? res.companyIds : [];
      })
    );
  }

  /**
   * только для сотрудников фуги
   * сохранение список компании которые они обслуживает
   */
  setServicedCompany(companyIds: Array<number>) {
    // TODO сейчас сохраняется только company
    // этому остальные поля здесь создаются
    const filter = {
      groupId: 1,
      companyIds: companyIds,
      form: 1,
      codes: ['1'],
      beforeDate: '',
      afterDate: '',
      registryId: 0
    };
    return this.httpClient.post('api/registry/filter', filter).pipe(
      map(response => {
        this.requestCacheService.delete('ServiceCompany');
        return response;
      })
    );
  }

}
