import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  private url = 'api/'

  constructor(
    private httpClient: HttpClient
  ) {
  }

  getAllNotificationForAdmin<R>() {
    return this.httpClient.get<R>(`${this.url}notifications`)
  }

  getActiveNotifications() {
    return this.httpClient.get(`${this.url}active-notifications`)
  }

  addNotification(notification?: string) {
    return this.httpClient.post(this.url + 'add-notification', {
      message: notification
    })
  }

  deactivateAnnouncement(notificationId?: number) {
    return this.httpClient.post(this.url + `notification-activate?id=${notificationId}`, {})
  }

  deleteNotification(notificationId?: number) {
    return this.httpClient.post(this.url + `delete-notification?id=${notificationId}`, {})
  }
}
