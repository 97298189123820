import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core'
import {AuthenticationService} from '../../../services/authentication.service'
import {UserRoleCodes} from '../../../constants/user-role-codes'

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {


  @Input() set collapsed(value: boolean) {
    this.isCollapsed = value
    this.collapsedChange.emit(this.isCollapsed);
  }

  get collapsed() {
    return this.isCollapsed;
  }

  @Output() collapsedChange = new EventEmitter<boolean>();

  isCollapsed = false;

  menu = [
    {
      label: 'admin.menu.users',
      icon: 'team',
      type: 'nz-menu-item',
      children: [
        {
          label: 'users.menu.list',
          url: 'users/list',
          icon: 'database'
        },
        {
          label: 'users.menu.registry',
          url: 'users/registry',
          icon: 'database'
        },
        {
          label: 'users.menu.report',
          url: 'users/report',
          icon: 'database'
        }
      ]
    },
    {
      label: 'formList.title',
      icon: 'menu',
      children: [
        {
          label: 'Актуальные записи',
          url: 'forms-list/actual',
          icon: 'database'
        },
        {
          label: 'admin.menu.exclude',
          url: 'forms-list/excluded',
          icon: 'database'
        },
        {
          label: 'main.header.finance',
          url: 'finance',
          icon: 'database'
        }
      ]
    },
    {
      label: 'admin.menu.registry',
      icon: 'menu',
      children: [
        {
          label: 'registry.basisFonds',
          url: '/base-fonds',
          icon: 'container'
        },
        {
          label: 'registry.company',
          url: 'company/tabs',
          icon: 'bank'
        }
      ]
    },
    {
      label: 'sub-register.title',
      icon: 'menu',
      children: [
        {
          label: 'admin.menu.actual',
          url: 'sub-register/actual',
          icon: 'database'
        },
        {
          label: 'admin.menu.exclude',
          url: 'sub-register/excluded',
          icon: 'database'
        }
      ]
    },
    {
      label: 'admin.menu.real-estate.name',
      icon: 'menu',
      children: [
        {
          label: 'admin.menu.real-estate.constitutive-document',
          url: 'real-estate/constitutive-document',
          icon: 'database'
        },
        {
          label: 'admin.menu.real-estate.title-document',
          url: 'real-estate/title-document',
          icon: 'database'
        },
        {
          label: 'admin.menu.real-estate.report',
          url: 'real-estate/report',
          icon: 'database'
        }
      ]
    },
    {
      label: 'admin.menu.select-company',
      url: 'select-services-company',
      icon: 'bank'
    },
    {
      label: 'admin.menu.catalogs.self',
      icon: 'menu',
      children: [
        {
          label: 'admin.menu.catalogs.company-types',
          url: 'catalogs/company-types',
          icon: 'border',
        },
        {
          label: 'admin.menu.catalogs.title-deed-document',
          url: 'catalogs/title-deed-document',
          icon: 'border',
        },
        {
          label: 'admin.menu.catalogs.title-ceftificate-document',
          url: 'catalogs/title-ceftificate-document',
          icon: 'border',
        },
        {
          label: 'admin.menu.catalogs.encumbrance-types',
          url: 'catalogs/encumbrance-types',
          icon: 'border',
        },
        {
          label: 'admin.menu.catalogs.restriction-types',
          url: 'catalogs/restriction-types',
          icon: 'border',
        },
        {
          label: 'admin.menu.catalogs.techical-condition-of-building',
          url: 'catalogs/techical-condition-of-building',
          icon: 'border',
        },
        {
          label: 'admin.menu.catalogs.legal-form',
          url: 'catalogs/legal-form',
          icon: 'border',
        },
        {
          label: 'admin.catalogs.userTypes',
          url: 'catalogs/user-types',
          icon: 'border',
        },
        {
          label: 'admin.catalogs.industry',
          url: 'catalogs/industry',
          icon: 'border',
        },
        {
          label: 'admin.menu.catalogs.land-plot-use',
          url: 'catalogs/land-plot-use',
          icon: 'border'
        },
        {
          label: 'Руководство Фонда',
          url: 'leadership',
          icon: 'border'
        },
        {
          label: 'admin.menu.catalogs.privatization-method-types',
          url: 'catalogs/privatization-method-types',
          icon: 'border'
        },
        {
          label: 'admin.menu.catalogs.use-object-types',
          url: 'catalogs/use-object-types',
          icon: 'border'
        },
        {
          label: 'admin.menu.catalogs.purpose-types',
          url: 'catalogs/purpose-types',
          icon: 'border'
        },
        {
          label: 'admin.menu.catalogs.wall-material-types',
          url: 'catalogs/wall-material-types',
          icon: 'border'
        }
      ]
    },
    {
      label: 'Отчёт',
      url: 'report/report',
      icon: 'fund',
    },
    {
      label: 'Объявления',
      url: 'announcement/announcement',
      icon: 'menu',
    }
  ];

  constructor(
    private authService: AuthenticationService
  ) {
  }

  ngOnInit() {
    // if (this.authService.checkRole(UserRoleCodes.ROLE_SUPER_ADMIN)) {
    //   this.menu.push(
    //     {
    //       label: 'Период',
    //       url: 'period/period',
    //       icon: 'menu',
    //     },
    //   )
    // }
    if (this.authService.checkRoles([UserRoleCodes.ROLE_ADMIN, UserRoleCodes.ROLE_SUPER_ADMIN])) {
      this.menu.push(
        {
          label: 'Период',
          url: 'period/period',
          icon: 'menu',
        },
      )
      this.menu.push({
        label: 'Поиск по ЕНИ',
        url: 'find-by-eni',
        icon: 'search'
      });
    }
  }

}
