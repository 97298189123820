export enum UserRoleCodes {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_REGISTRY_EMPLOYEE = 'ROLE_REGISTRY_EMPLOYEE',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_BOOKER = 'ROLE_BOOKER',
  ROLE_PROPERTY_RELATIONS = 'ROLE_PROPERTY_RELATIONS',
  ROLE_PRIME_MINISTER = 'ROLE_PRIME_MINISTER'
}

export enum USER_ROLE_SHORT {
  ROLE_BOOKER = 'ROLE_BOOKER',
  ROLE_MANAGER = 'ROLE_MANAGER',
}

export const USER_ROLE_NAMES = {
  [USER_ROLE_SHORT.ROLE_BOOKER]: 'Бухгалтер',
  [USER_ROLE_SHORT.ROLE_MANAGER]: 'Руководитель'
}

export const USER_ROLE_OPTIONS = Object.keys(USER_ROLE_SHORT).map(
  (item, i) => {
    return {
      code: item,
      label: USER_ROLE_NAMES[item]
    }
  }
)
