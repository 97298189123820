import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

const appRoutes: Routes = [

  {
    path: '',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'not_found',
    loadChildren: () => import('./modules/not-found-page/not-found-page.module').then(m => m.NotFoundPageModule)
  },
  {path: 'users', loadChildren: () => import('./modules/users/user.module').then(m => m.UserModule)},
  {path: 'company', loadChildren: () => import('./modules/company/company.module').then(m => m.CompanyModule)},
  {
    path: 'base-fonds',
    loadChildren: () => import('./modules/base-fonds/base-fonds.module').then(m => m.BaseFondsModule)
  },
  {
    path: 'forms-list',
    loadChildren: () => import('./modules/forms-list/forms-list.module').then(m => m.FormsListModule)
  },
  {
    path: 'sub-register',
    loadChildren: () => import('./modules/sub-register/sub-register.module').then(m => m.SubRegisterModule)
  },
  {
    path: 'real-estate',
    loadChildren: () => import('./modules/real-estate/real-estate.module').then(m => m.RealEstateModule)
  },
  {
    path: 'form-detail',
    loadChildren: () => import('./modules/forms-detail/form-detail.module').then(m => m.FormDetailModule)
  },
  {path: 'exclusion', loadChildren: () => import('./modules/exclusion/exclusion.module').then(m => m.ExclusionModule)},
  {path: 'mail', loadChildren: () => import('./modules/mail-box/mail-box.module').then(m => m.MailBoxModule)},
  {path: 'catalogs', loadChildren: () => import('./modules/catalogs/catalogs.module').then(m => m.CatalogsModule)},
  {
    path: 'period',
    loadChildren: () => import('./modules/actualization-period/actualization-period.module').then(m => m.ActualizationPeriodModule)
  },
  {
    path: 'period-for-manager',
    loadChildren: () => import('./modules/period-for-manager/act-period.module').then(m => m.ActPeriodModule)
  },
  {path: 'report', loadChildren: () => import('./modules/report/report.module').then(m => m.ReportModule)},
  {path: 'finish-period', loadChildren: () => import('./modules/finish-period/finish-period.module').then(m => m.FinishPeriodModule)},
  {path: 'announcement', loadChildren: () => import('./modules/announcement/announcement.module').then(m => m.AnnouncementModule)},
  {
    path: 'leadership',
    loadChildren: () => import('./modules/leadership/leadership.module').then(m => m.LeadershipModule)
  },
  {path: 'find-by-eni', loadChildren: () => import('./modules/find-by-eni/find-by-eni.module').then(m => m.FindByEniModule)},
  {path: 'mail', loadChildren: () => import('./modules/mail-box/mail-box.module').then(m => m.MailBoxModule)},
  {path: 'archive', loadChildren: () => import('./modules/archive/archive.module').then(m => m.ArchiveModule)},
  {path: 'forms', loadChildren: () => import('./modules/forms-create-update/forms.module').then(m => m.FormsModule)},
  {
    path: 'form7-identical-objects',
    loadChildren: () => import('./modules/form7-identical-objects/form7-identical-objects.module').then(m => m.Form7IdenticalObjectsModule)
  },
  {path: 'manual', loadChildren: () => import('./modules/manual/manual.module').then(m => m.ManualModule)},
  {path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path: 'select-services-company', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule)},
  {path: 'locality', loadChildren: () => import('./modules/address/address.module').then(m => m.AddressModule)},
  {path: 'finance', loadChildren: () => import('./modules/finance/finance.module').then(m => m.FinanceModule)},
  {path: '**', redirectTo: 'not_found'},

];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
