import { Component, OnInit } from '@angular/core'
import { AuthenticationService } from '../../services/authentication.service'
import { Router } from '@angular/router'
import { UserRoleCodes } from '../../constants/user-role-codes'
import {AnnouncementService} from '../../services/announcement.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  isCollapsed = false

  isAdminPanel = false;

  isManagerPanel = false;
  isPrimePanel = false;

  isAuthorised = false;
  announcements: unknown = [];
  birthDay: Date = new Date();

  menuItems = [
    // {link: 'dashboard', title: 'main.header.desktop'},
    {link: 'forms-list/tabs', title: 'main.header.registry'},
    // {link: 'sub-register', title: 'sub-register.title'},
    // {link: 'archive', title: 'main.header.archive'},
    // {link: 'exclusion', title: 'main.header.exclude'},
    {link: 'report/report', title: 'main.header.report'},
    // {link: 'finance', title: 'main.header.finance'}
  ]

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    public announService: AnnouncementService
  ) {
  }

  username: string;
  idCurrentUser: number;

  logout() {
    this.authService.logout();
  }
  getActiveAnnoun() {
    this.announService.getActiveNotifications().subscribe(res => {
      if (res) { this.announcements = res } else { this.announcements = [] }
    })
  }
  ngOnInit() {
    this.authService.authorized.subscribe(res => {
      if (res) {
        this.isAuthorised = true;
        this.username = this.authService.currentUser.username;
        this.idCurrentUser = this.authService.getCurrentUserId()
        this.getActiveAnnoun()
        this.isAdminPanel = this.authService.checkRoles([UserRoleCodes.ROLE_ADMIN, UserRoleCodes.ROLE_SUPER_ADMIN, UserRoleCodes.ROLE_REGISTRY_EMPLOYEE])
        this.isManagerPanel = this.authService.checkRoles([UserRoleCodes.ROLE_MANAGER])
        this.isPrimePanel = this.authService.checkRoles([UserRoleCodes.ROLE_PRIME_MINISTER])
      } else {
        this.isAuthorised = false;
        this.isManagerPanel = false;
        this.isPrimePanel = false;
        this.username = '';
        this.idCurrentUser = null;
        this.isAdminPanel = false;
      }
    });
  }

  profile() {
    this.router.navigate(['users', 'list', 'bio', this.idCurrentUser])
  }

  manual() {
    this.router.navigate(['manual']);
  }

  mail() {
    this.router.navigate(['mail']);
  }

}
